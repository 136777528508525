export const initialValues = {
  personal_details: {
    lead_source: "",
    title: "",
    first_name: "",
    surname: "",
    date_of_birth: "",
    email: "",
    mobile: "",
    landline: "",
    address_1: "",
    address_2: "",
    address_3: "",
    postcode: "",
    address_town: "",
    country: "",
    emergency_contact_name: "",
    emergency_contact_phone: "",
    emergency_contact_relationship: "",
    nationality: "",
    passport_number: "",
    passport_expiry_date: "",
    has_medical_conditions: "",
    medical_conditions_details: "",
    has_full_driving_licence: "",
  },
  method_of_transport: [],
  availability: {
    available_from: "",
    shifts: {
      days: 0,
      nights: 0,
      weekends: 0,
    },
  },
  bank_details: {
    account_holder: "",
    account_number: "",
    sort_code: "",
    national_insurance: "",
  },
  id_card: {
    entitled_to_work_in_uk: "",
    required_id: "",
    has_physical_or_mental_condition: "",
    condition_details: "",
    has_been_convicted_or_criminal_offence: "",
  },
  education: {
    is_required: "",
    facility_name: "",
    course_name: "",
    start_date: "",
    end_date: "",
    qualifications: "",
    address: "",
    contact_name: "",
    contact_number: "",
    flt_plant_licenses: "n/a",
  },
  employment_history: {
    company_1: {
      company_name: "",
      job_title: "",
      date_from: "",
      date_to: "",
      reason_for_leaving: "",
      duties: "",
    },
    company_2: {
      company_name: "",
      job_title: "",
      date_from: "",
      date_to: "",
      reason_for_leaving: "",
      duties: "",
    },
    company_3: {
      company_name: "",
      job_title: "",
      date_from: "",
      date_to: "",
      reason_for_leaving: "",
      duties: "",
    },
  },
  health_and_medical_questionnaire: {
    eyesight_and_vision: {
      any_problems: "",
      wears_glasses: "",
      problems_corrected_by_glasses: "",
      colour_blind: "",
      difficulty_differentiating_colours: "",
      has_had_eye_operation: "",
      has_had_retina_scan: "",
      condition_details: "",
    },
    heart_and_lungs: {
      has_suffered_from_high_blood_pressure: "",
      has_had_stroke_or_heart_attack: "",
      has_suffered_from_heart_disease: "",
      has_suffered_from_angina: "",
      has_had_heart_surgery: "",
      has_suffered_from_asthma_bronchitis_or_similar: "",
      has_suffered_from_disease_not_mentioned: "",
      condition_details: "",
    },
    back: {
      has_suffered_from_sciatica: "",
      has_suffered_from_lumbago: "",
      has_suffered_from_slipped_disc: "",
      has_suffered_from_prolapsed_disc: "",
      has_suffered_from_other_back_problems: "",
      has_suffered_from_arthritis: "",
      has_suffered_from_other_form_of_inflammation: "",
      has_suffered_from_repetitive_strain_injury: "",
      has_suffered_from_white_finger_or_carpel_tunne_syndrome: "",
      has_restricted_mobility_of_arms_hands_or_fingers: "",
      has_restrictions_to_general_mobility: "",
      has_been_disqualified_from_driving_due_to_ill_health: "",
      is_registered_as_disabled: "",
      condition_details: "",
    },
    general: {
      has_had_serious_head_injury: "",
      has_suffered_from_nervous_disorders: "",
      has_suffered_from_depression: "",
      has_suffered_from_drug_alcohol_addiction: "",
      has_suffered_from_diabetes: "",
      has_suffered_from_epilepsy: "",
      suffers_from_full_or_partial_deafness: "",
      has_become_sensitized_to_chemical_substances: "",
      has_had_accident_at_work: "",
      has_injured_fingers_hands_wrist_elbow_or_arm: "",
      has_had_arm_operation_or_injections_to_wrist: "",
      has_other_medical_related_matters: "",
      condition_details: "",
    },
    consent_to_data_sharing: 0,
  },
  equal_opportunities_monitoring: {
    has_disabilities: "",
    has_registered_disability: "",
    ethnic_origin: "",
    ethnic_origin_other: "",
  },
  team_lifting: {
    understands: 0,
  },
  work_time_directive: {
    understands: 0,
  },
  criminal_record: {
    holds_a_criminal_records_bureau_disclosure: "",
    disclosure_details: "",
    convicted_of_offence_under_rehabilitation_act: "",
    under_act_offence_details: "",
    convicted_of_offence_not_under_rehabilitation_act: "",
    not_under_act_offence_details: "",
    has_security_clearance: "",
    security_clearance_granted: "",
    security_clearance_expires: "",
    confirm_information_accurate: 0,
    will_inform_of_changes: 0,
  },
  previous_employment: {
    previous_employer: "",
    temp_or_perm: "",
    with_agency: "",
    agency_name: "",
    number_of_temps: "",
    pay_rate: "",
    shift_pattern: "",
  },
  privacy_notice: {
    understands: 0,
  },
}
