import React, { useRef, useState } from "react"
import { Formik } from "formik"
import { Helmet } from "react-helmet"

import { dataURItoBlob } from "../../utils/form-data"
import {
  submitForm,
  handleSubmitResponse,
  jsonToFormData,
} from "../../services"

import ArgosLogo from "../../assets/images/logos/argos-logo.jpg"
import { Table } from "../../style/tables"
import {
  LCard,
  Col2,
  Col2Constant,
  FieldSet,
  HeaderWithLogo,
} from "../../style/containers"

import EmploymentHistory from "../../components/EmploymentHistory"
import HealthAndMedicalQuestionnaire from "../../components/HealthAndMedicalQuestionnaire"
import Layout from "../../components/layout"
import PersonalDetails from "../../components/PersonalDetails"
import SButton from "../../components/button"
import Signature from "../../components/Signature"
import Form from "../../components/form/FormHelper"
import {
  initialValues,
  validationSchema,
} from "../../components/ArgosApplication"
import {
  BankDetails,
  ContractOfEmployment,
  CriminalRecord,
  Education,
  EqualOpportunitiesMonitoring,
  IDCard,
  PrivacyNotice,
  TeamLifting,
  WorkingTimeDirective,
  PreviousEmployment,
} from "../../components/IndustrialApplication"
import { FormikCheckbox, FormikInput } from "../../components/form"
import { CheckboxGroup } from "../../components/form/checkbox"

const IndustrialApplication = () => {
  const sigCanvas = useRef({})
  const [signatureDrawn, setSignatureDrawn] = useState()
  const [errorText, setErrorText] = useState([])

  const createCandidate = values => {
    return (
      values.personal_details.title +
      ". " +
      values.personal_details.first_name +
      " " +
      values.personal_details.surname
    )
  }

  const onSubmit = async (values, actions) => {
    if (signatureDrawn) {
      // Internet Explorer 6-11
      var isIE = /*@cc_on!@*/ false || !!document.documentMode

      // Edge 20+
      var isEdge = !isIE && !!window.StyleMedia

      let file

      const dataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png")
      const blobContent = dataURItoBlob(dataURL)
      const formData = jsonToFormData(values)

      if (isEdge || isIE) {
        file = new Blob([blobContent], "signature.png", {
          type: "image/png",
        })
      } else {
        file = new File([blobContent], "signature.png", { type: "image/png" })
      }

      formData.append("signature", file)

      let res = await submitForm(formData, "")
      handleSubmitResponse(res, setErrorText)
    } else {
      setErrorText([
        "You must sign a signature before submitting. If you've already added a signature please clear and sign again",
      ])
    }
    actions.setSubmitting(false)
  }
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Argos Application Form</title>
      </Helmet>
      <LCard>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, values, isSubmitting }) => (
            <Form>
              <HeaderWithLogo>
                <h1>Argos Application Form</h1>
                <img src={ArgosLogo} alt="Argos Logo" />
              </HeaderWithLogo>
              <h2>Personal details</h2>
              <PersonalDetails values={values} setFieldValue={setFieldValue} />
              <h2>Availability</h2>
              <Col2Constant>
                <Col2Constant>
                  <FormikInput
                    name="availability.available_from"
                    type="text"
                    label="Date available to start work"
                  />
                </Col2Constant>
              </Col2Constant>
              <Col2>
                <CheckboxGroup>
                  <b>Shift types available for</b>
                  <FormikCheckbox
                    label="Days"
                    name="availability.shifts.days"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="Nights"
                    name="availability.shifts.nights"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="Weekends"
                    name="availability.shifts.weekends"
                    withoutError={true}
                  />
                </CheckboxGroup>
              </Col2>
              <h2>Bank details</h2>
              <BankDetails />
              <h2>ID Card</h2>
              <IDCard values={values} setFieldValue={setFieldValue} />
              <h2>Education</h2>
              <Education values={values} setFieldValue={setFieldValue} />
              <h2>Employment History</h2>
              <EmploymentHistory />
              <h2>Health and Medical Questionnaire</h2>
              <HealthAndMedicalQuestionnaire
                values={values}
                setFieldValue={setFieldValue}
              />
              <h2>Equal Opportunities Monitoring</h2>
              <EqualOpportunitiesMonitoring
                values={values}
                setFieldValue={setFieldValue}
              />
              <h2>Team Lifting</h2>
              <TeamLifting />
              <h2>Working Time Directive</h2>
              <WorkingTimeDirective
                values={values}
                createCandidate={createCandidate}
              />
              <h2>Criminal Record</h2>
              <CriminalRecord values={values} setFieldValue={setFieldValue} />
              <h2>Previous Employment Sheet</h2>
              <PreviousEmployment
                values={values}
                setFieldValue={setFieldValue}
              />
              <h2>Privacy Notice</h2>
              <PrivacyNotice />
              <h2>Contract of Employment</h2>
              <ContractOfEmployment
                values={values}
                createCandidate={createCandidate}
              />
              <h2>Confirmation of Temporary Assignment</h2>
              <FieldSet>
                <p>
                  This confirms you will be working on a contract of services
                  via Pin Point Recruitment at DHL Argos, on a temporary
                  contract:
                </p>
                <Table>
                  <tbody>
                    <tr>
                      <td>
                        <b>Position:</b>
                      </td>
                      <td>Warehouse Operative</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Location:</b>
                      </td>
                      <td>Faverdale Industrial Estate, Darlington, DL3 0UR</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Reporting To:</b>
                      </td>
                      <td>
                        <p>Keith Whitaker (A Shift Manager)</p>
                        <p>Ron Charlton (B Shift Manager)</p>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <p>Earlies £8.21</p>
                        <p>Lates £8.21</p>
                        <p>Nights £8.21</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Shift times:</b>
                      </td>
                      <td>
                        <p>Earlies 6am – 2pm</p>
                        <p>Lates 2pm – 10pm</p>
                        <p>Nights 10pm – 6am</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Contract Length:</b>
                      </td>
                      <td>12 weeks</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Training:</b>
                      </td>
                      <td>
                        Candidates will undergo initial 4 hour training on site
                        under the guidance of qualified Argos Trainers, Paul
                        Mussett and Ian Rhodes. Candidate will be made aware of
                        health and safety in the workplace, a guided tour of the
                        site and basic training.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Job Spec:</b>
                      </td>
                      <td>
                        Duties include completion of paperwork, ensuring stock
                        levels are maintained, picking and packing, taking
                        delivery of goods and supplies, checking for damaged or
                        missing items, moving stock around by hand, using
                        lifting equipment, storing goods in line with
                        instructions, loading goods for despatch, and cleaning
                        work areas.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Health &amp; Safety details:</b>
                      </td>
                      <td>
                        1 hour Health and Safety training on induction. This
                        includes awareness of heavy lifting and dangers around
                        the work place. Health and Safety training carried out
                        by qualified Argos trainers.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>PPE:</b>
                      </td>
                      <td>Safety Boots, Yellow Hi-Vis Vest.</td>
                    </tr>
                  </tbody>
                </Table>
              </FieldSet>
              <FieldSet>
                <h2>Your first shift</h2>
                <p>
                  On your first shift you will need to sign in and out of the
                  fire register at reception. On your second shift you will be
                  given a swipe card which is your responsibility.
                </p>
                <p>
                  All agency staff are to be in possession of a swipe card to
                  gain access to site, without these cards then access to site
                  may be refused. 
                </p>
                <p>
                  <b>
                    If you lose your swipe card you have 3 days to find this
                    before you will be charged £5 for a replacement.
                  </b>
                </p>
                <p>
                  <b>
                    If you leave or are dismissed from the contract you must
                    return your card within 3 days or you will be deducted £5
                    from you last wage.
                  </b>
                </p>
              </FieldSet>
              <FieldSet>
                <h2>Mobile Phone Policy</h2>
                <p>
                  This briefing is to make you aware that the carrying of
                  personal mobile phones in the main warehouse and yard by all
                  colleagues at Faverdale, is to stop from 0600 am on Monday 1st
                  August 2015.
                </p>
                <p>
                  The customer has raised concerns on both the security and
                  Health and Safety risks of allowing colleagues to use or carry
                  personal mobile phones in the warehouse. This decision follows
                  the use of personal mobile phones in other HRG sites.
                </p>
                <p>
                  Colleagues should either secure their mobiles within their
                  personal locker, their car or refrain from bringing them onto
                  site. We do support the use of mobile phones, but this should
                  be restricted to non-warehouse areas at break times.
                </p>
                <p>
                  Site management are authorised to use/carry mobile phones in
                  the warehouse and yard areas, for business needs only. All
                  phones for business will need to be on silent and are only
                  used in a safe area away from MHE. The IMEA numbers will be
                  recorded by VSG.
                </p>
                <p>
                  I understand that colleagues may need to be contacted for
                  emergencies. There will be a dedicated number on site that
                  will be available for this; you will receive a card with this
                  number on after the brief.
                </p>
                <p>
                  All colleagues, including agency colleagues, will be required
                  to sign to acknowledge they understand the new policy on
                  mobile phone use in the warehouse at Faverdale.
                </p>
                <p>
                  Any instance of colleagues being found to have a personal
                  mobile phone in the warehouse, will be dealt with through the
                  normal disciplinary procedure.
                </p>
              </FieldSet>
              <Signature
                signatureDrawn={signatureDrawn}
                setSignatureDrawn={setSignatureDrawn}
                sigCanvas={sigCanvas}
              />
              {errorText
                ? errorText.map(e => (
                    <Error style={{ marginTop: "1rem" }} key={e}>
                      {e}
                    </Error>
                  ))
                : null}
              <SButton type="submit" disabled={isSubmitting}>
                Submit
              </SButton>
            </Form>
          )}
        </Formik>
      </LCard>
    </Layout>
  )
}

export default IndustrialApplication
